import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { navigate } from "gatsby"
import { formModalVariants } from '../../lib/animation';

import { PortableTextBlock } from "../ui/portableTextBlock"

const Inner = ({ cartProject, checkoutTitle, checkoutText, cartItems, submitActive, setSubmitActive }) => {

    const [userName, setUserName] = useState();  
    const [userEmail, setUserEmail] = useState(undefined);  
    const [userPhone, setUserPhone] = useState(undefined);  
    const [userCompany, setUserCompany] = useState('');  
    const [userNotes, setUserNotes] = useState(undefined);  
    const [checkoutStatus, setCheckoutStatus] = useState(undefined);  
    const checkoutItems = undefined; 

    useEffect(()=>{
      const email = localStorage.getItem('projectEmail');

      if(email){
        setUserEmail(JSON.parse(email))
      }
    },[])

    function buildTable(data){
        
		var contentHtml = '';
        userNotes !== undefined && (contentHtml += `<p>${userNotes}</p>`);

    data.map((item) => {

      contentHtml += `<p><strong>${item.product ? item.product : 'Item'}</strong></p><ul>`

      // item.product && (contentHtml += `<li>Product: ${item.product}</li>`)
      item.size && (contentHtml += `<li>Size: ${item.size}${item.size === 'Custom' ? (
        ` - ${item.customWidth && `${item.customWidth}W`} ${item.customLength && ` ${item.customLength}L`}${item.customHeight && ` ${item.customHeight}H`}${item.customUnit && ` ${item.customUnit}`}`
      ) : (``)}</li>`)      
      item.orientation && (contentHtml += `<li>Orientation: ${item.orientation}</li>`)
      item.material && (contentHtml += `<li>Material: ${item.material}${item.specialtySpec ? ` - ${item.specialtySpec}` : ``}</li>`)
      item.laminate && (contentHtml += `<li>Laminate: ${item.laminate}</li>`)
      item.printing && (contentHtml += `<li>Printing: ${item.printing}</li>`)
      item.colour && (contentHtml += `<li>Colour: ${item.colour}</li>`)
      item.closure && (contentHtml += `<li>Closure: ${item.closure}</li>`)
      item.embossing && (contentHtml += `<li>Emboss/Deboss: ${item.embossing}</li>`)
      item.texture && (contentHtml += `<li>Textures: ${item.texture}</li>`)
      item.foil && (contentHtml += `<li>Foils: ${item.foil}</li>`)
      item.finish && (contentHtml += `<li>Finish: ${item.finish}</li>`)
      item.finish && (contentHtml += `<li>Finish: ${item.finish}</li>`)
      item.quantity && (contentHtml += `<li>Quantity: ${item.quantity}${item.quantityCustom ? item.quantityCustom : ''}</li>`)      
      item.leadTime && (contentHtml += `<li>Lead Time: ${item.leadTime}</li>`)
      item.notes && (contentHtml += `<li>Additional Notes: ${item.notes}</li>`)
        
      contentHtml += '</ul>'
    })		

    return contentHtml
    
	}

    const createNote = async (id) => {         
      
        const theData = buildTable(cartItems)    
  
        const response = await window
          .fetch(`/api/addNote`, {
            method: `POST`,
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              dealId: id,
              content: theData
          }),          
          })      
          .then((response) => response.json())
          .then((data) => {
            if (data.success === false) {                
                setCheckoutStatus(false)                            
                return
            }
            if (data.success === true) {                                                      
              // setCheckoutStatus(true)
              const isBrowser = typeof window !== "undefined"
              if (isBrowser) {
                window.localStorage.setItem('cart', []);
              }            
              navigate(`/enquiry-submitted`)            
            }
          });
      }
      
          
  
      const createDeal = async () => {      
        setCheckoutStatus('submitting')
        const response = await window
          .fetch(`/api/addDeal`, {
            method: `POST`,
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              project: cartProject !== null ? cartProject :  userName,
              name: userName,
              company: userCompany,
              email: userEmail,
              phone: userPhone,
              note: userNotes,
              cart: cartItems
          }),          
          })      
          .then((response) => response.json())
          .then((data) => {
            if (data.success === false) {              
                setCheckoutStatus(false)                            
                return
            }
            if (data.success === true) {                                                                  
              createNote(data.data.id)            
            }
          });

          updateContact(userEmail)
      }

      const updateContact = (email) =>{
        fetch(`/api/builder/completed/${email}`)
      }

    return (
        <motion.div 
            className="fixed inset-0 w-full h-screen bg-black/30 flex items-center justify-center"
            initial="closed"
            animate="open"
            exit="closed"
            variants={formModalVariants}            
        >
            <div className="w-5/6 max-w-2xl mx-auto bg-black rounded-card p-6 lg:px-10 relative">
                <div className="flex justify-end">
                <div className="cursor-pointer lg:hover:underline" onClick={()=>{setSubmitActive(false)}}>Close</div>
                </div>
                <div className="text-lg lg:text-xl">
                    {checkoutTitle !== null && <div>{checkoutTitle}</div>}
                    <div>
                    {cartItems && cartItems.length > 0 ? (
                      cartItems.length > 1 ? `${cartItems.length} items` : `${cartItems.length} item`
                    ) : (
                      `Your cart is empty.`
                    )}
                    </div>
                </div>

                {checkoutText && <PortableTextBlock className="prose mt-6" text={checkoutText} />}

                <form class="mt-6 styled-form grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-6">
                    <input type="text" name="name" placeholder="Name*" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                    <input type="text" name="company" placeholder="Company*" value={userCompany} onChange={(e) => setUserCompany(e.target.value)} required />
                    <input type="email" name="email" placeholder="Email address*" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}  required />
                    <input type="tel" name="phone" placeholder="Phone number*" value={userPhone} onChange={(e) => setUserPhone(e.target.value)}  required />
                    <textarea className="lg:col-span-2 h-24" name="notes" placeholder="Additional Notes" value={userNotes} onChange={(e) => setUserNotes(e.target.value)} />
                    {cartItems && cartItems.length > 0 && (
                    <>  
                    
                    <div>
                        {checkoutStatus === 'submitting' && (
                        <>                        
                        <p>Please do not refresh your page.</p>
                        </>
                        )}
                        {checkoutStatus === true && (
                        <p>Submission successful.</p>
                        )}
                        {checkoutStatus === false && (
                        <p>There was an error.</p>
                        )}
                    </div>

                    <div className="flex justify-end mb-gutter">
                        <button onClick={()=>{createDeal()}} type="button" className={`${checkoutStatus === 'submitting' ? 'pointer-events-none' : ''} btn-rnd-white ${userName && userEmail && userPhone ? 'cursor-pointer' : 'opacity-40 pointer-events-none'}`}>{checkoutStatus === 'submitting' ? 'Submitting' : 'Submit project'}</button>
                    </div>            
                    <div className={`absolute px-6 lg:px-10 pb-4 inset-0 w-full h-full bg-black/70 rounded-card flex items-end ${checkoutStatus === 'submitting' ? '' : 'opacity-0 pointer-events-none'}`}>
                        <div className="submission-loading-bar"></div>
                    </div>
                    </>
                    )}                                          
                </form>

            </div>
        </motion.div>
    )
}
const SubmitProject = ({ cartProject, checkoutTitle, checkoutText, cartItems, submitActive, setSubmitActive }) => {
    
    const [innerActive, setInnerActive] = useState(false);
  return (
    <AnimatePresence exitBeforeEnter>
        {submitActive && <Inner {...{cartProject, checkoutTitle, checkoutText, cartItems, submitActive, setSubmitActive}} />}
    </AnimatePresence>
  )
}

export default SubmitProject