import React, { useEffect, useState } from 'react';
import { navigate } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';
import { useBuilder } from '../GraphQl/useBuilder';

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import SubmitProject from '../components/builder/submitProject';
import { PortableTextBlock } from "../components/ui/portableTextBlock"

const CartTemplate = ({}) => { 
  
  const { 
    cartTitle,
    checkoutTitle,
    checkoutText,
    cartEmptyDescription,
    cartEmptyButton
   } = useBuilder()
  
  const { 
    setFooterHidden, 
    setHeaderDark, 
    cartProject, 
    setCartProject, 
    setFooterTheme,
    setCartEditItem
  } = useSiteState();

  useEffect(() => {    
    document.documentElement.setAttribute('data-theme', `theme-black`);
    document.documentElement.style.setProperty(`--doc-bg`, '#141414');                  
    setHeaderDark(false)    
    setFooterHidden(true)
    setFooterTheme('theme-none')
  }, [setFooterHidden, setFooterTheme, setHeaderDark]);

  const [cartItems, setCartItems] = useState([]);
  const [editProjectName, setEditProjectName] = useState(false);  
  const [submitActive, setSubmitActive] = useState(false);

    useEffect(() => {
      let items = []
      if (localStorage.getItem("cart")) {
        items = JSON.parse(localStorage.getItem('cart'));
      } else {
        items = []
      }    
    if (items) {
        setCartItems(items);
    }
    }, [setCartItems]);    

    function handleEditProjectName() {
        localStorage.setItem('projectName', JSON.stringify(cartProject));        
        setEditProjectName(false)
    }

    function handleCartItemEdit(item) {
      setCartEditItem(item)
      navigate(`/builder/${item.slug.current}`)
    }

    function handleCartItemDelete(item) {
      const updated = cartItems
      var i = 0;
      while (i < updated.length) {
        if (updated[i] === item) {
          updated.splice(i, 1);
        } else {
          ++i;
        }
      }            
      setCartItems([...updated])      
      localStorage.setItem('cart', JSON.stringify(cartItems));        
    }        

  return (
    <Layout>      
      <Seo metaTitle="Cart" hidden={true}/>
      <div className="block w-full h-24 lg:h-header"></div>
      <section id="cart" className="grid-std lg:items-start px-gutter lg:px-0 lg:pt-20">
            <div className="col-span-full lg:col-start-2 lg:col-end-10 lg:sticky lg:top-0 mb-12 lg:mb-0">
                <h1 className="text-lg md:text-xl lg:text-2xl">
                    <div>{cartTitle ? cartTitle : 'Cart'}</div>
                    <div>
                    {cartItems && cartItems.length > 0 && (
                      cartItems.length > 1 ? `${cartItems.length} items` : `${cartItems.length} item`
                    )}
                    </div>
                </h1>
            </div>
            <ul className="col-span-full lg:col-start-12 lg:col-end-26 px-gutter lg:px-0">
            <li>
                <form className="flex items-baseline justify-between md:justify-start mb-2">
                  <input className={`rounded-none bg-transparent appearance-none py-2 outline-none border-b border-transparent text-base md:text-lg mb-4 trans-std mr-2 ${editProjectName ? 'border-white' : 'pointer-events-none'}`} type="text" placeholder="Untitled project" value={cartProject}  onChange={(e) => setCartProject(e.target.value)} name="cartProject"></input>                  
                  {editProjectName ? (
                    <button type="button" className="outline-none focus:underline hover:underline" onClick={()=>{handleEditProjectName()}}>Save</button>
                  ) : (
                    <button type="button" className="outline-none focus:underline hover:underline" onClick={()=>{setEditProjectName(!editProjectName)}}>Edit</button>
                  )}
                </form>                
              </li>
            {cartItems.length > 0 ? (
                cartItems.map((item, index) => {
                    return (
                        <li className="grid grid-cols-4 grid-flow-row gap-4 pb-4 mb-4 border-b border-white/50">
                          <div className="col-span-4 md:col-span-1 overflow-hidden transition-all duration-300 hover:rounded-card">
                            <Link to={`/packaging/${item.slug.current}`}>
                              <GatsbyImage 
                                  image={item.image.asset.gatsbyImageData} 
                                  alt={item.product} 
                                  className="block w-full h-auto" 
                              />
                            </Link>
                          </div>
                          <div className="col-span-3 md:col-span-2">
                              <p className="mb-8">{item.product}</p>
                              <table className="w-full table-fixed">
                                {item.size && (
                                  <tr><td className="opacity-50">Size:</td><td>{item.size}
                                  {item.size === 'Custom' && (
                                    <>
                                    {' - '}
                                    {item.customWidth && item.customWidth}
                                    {` X `}
                                    {item.customLength && item.customLength}
                                    {` X `}
                                    {item.customHeight && item.customHeight}
                                    {` `}
                                    {item.customUnit && item.customUnit}
                                    </>
                                  )}
                                  </td>
                                  </tr>
                                )}
                                {item.orientation && <tr><td className="opacity-50">Orientation:</td><td>{item.orientation}</td></tr>}

                                {item.closure && <tr><td className="opacity-50">Closure:</td><td>
                                {Array.isArray(item.closure) ? (
                                    item.closure.map((item, index) => {
                                      return (
                                        <>{item}{` `}</>
                                      )
                                    })
                                  ) : (
                                    <>{item.closure}</>
                                  )}      
                                  </td></tr>}
                                
                                {item.material && <tr><td className="opacity-50">Material:</td><td>{item.material}{item.specialtySpec && ` - ${item.specialtySpec}`}</td></tr>}

                                {item.materialType && <tr><td className="opacity-50">Material Type:</td><td>{item.materialType}</td></tr>}
                                
                                {item.laminate && item.laminate !== 'None' &&  <tr><td className="opacity-50">Laminate:</td><td>{item.laminate}</td></tr>}

                                {item.printing && <tr><td className="opacity-50">Printing:</td><td>{item.printing}</td></tr>}

                                {item.colour && <tr><td className="opacity-50">Colour:</td><td>{item.colour}</td></tr>}
                                
                                {item.colourItem && <tr><td className="opacity-50">Colour:</td>
                                  <td>
                                  {item.colourItem.map((item) => (
                                    <span className="inline-flex w-4 h-4 rounded-full border border-white/50 mr-2" style={{ background: item}}></span>
                                  ))}
                                  </td>
                                </tr>}
                                <tr><td className="opacity-50">Additions:</td><td>{item.embossing && item.embossing}{item.embossing && item.foil && ' | '}{item.foil && item.foil}</td></tr>
                                {item.quantity && <tr><td className="opacity-50">Quantity:</td><td>{item.quantity}{(item.quantity === 'Custom' && item.quantityCustom) && ` - ${item.quantityCustom}`}</td></tr>}
                                {item.dieline && <tr><td className="opacity-50">Dieline:</td><td>{item.dieline}</td></tr>}
                                {item.notes && <tr><td className="opacity-50">Notes:</td><td>{item.notes}</td></tr>}
                              </table>
                          </div>
                          <div className="col-span-1">
                            <div className="flex justify-end">
                              <button 
                                type="button" 
                                className="hover:underline mr-6" 
                                onClick={()=>{handleCartItemEdit(item)}}
                                >Edit</button>
                              <button 
                                type="button" 
                                className="opacity-50 hover:opacity-100 hover:text-pp-orange"
                                onClick={()=>{handleCartItemDelete(item)}}
                                >Remove</button>
                            </div>
                          </div>
                        </li>
                    )
                })
            ) : (
                <li className="flex flex-col items-start pt-4 text-white border-t border-white/50">
                  {cartEmptyDescription ? <PortableTextBlock className="prose md:max-w-md my-6" text={cartEmptyDescription} /> : <p>Your project is empty.</p>}
                  {cartEmptyButton !== null && <Link className="mt-12 lg:mt-20 btn-rnd-white" to={`/packaging`}>{cartEmptyButton}</Link>}                  
                </li>
            )}
            </ul>
            {cartItems && cartItems.length > 0 && (
              <div className="col-span-full flex justify-end px-gutter mt-20 lg:mt-32 mb-gutterDouble lg:mb-gutter">                
              <Link to="/packaging" type="button" className="btn-rnd flex items-center mr-4">Add more products</Link>
                <div onClick={()=>{setSubmitActive(true)}} className="btn-rnd-white">Submit project for quoting</div>
              </div>            
            )}            
      </section>
      <SubmitProject {...{cartProject, checkoutTitle, checkoutText, cartItems, submitActive, setSubmitActive}} />
    </Layout>
  );
};

export default CartTemplate;
